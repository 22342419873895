<template>
  <div>
    <!-- 列表区域-STR -->
    <l-table-common
      :data-source="tableData"
      @change="search"
    >
      <l-table-column data-index="id" title="流水号" />
      <l-table-column data-index="name" title="名字" />
      <l-table-column data-index="key" title="键" />
      <l-table-column data-index="value" title="值" />
      <l-table-column title="更多">
        <template slot-scope="scope">
          <l-button type="link" @click="editRow(scope)">编辑</l-button>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表区域-END -->

    <!-- 保存记录-STR -->
    <l-modal
      v-model="editDialog.show"
      :title="editDialog.data.id ? '修改记录' : '添加记录'"
      width="600px"
      cancel-text="取消"
      ok-text="确认"
      @ok="saveDialog"
    >
      <l-form-model
        ref="form"
        :model="editDialog.data"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 16 }"
      >
        <l-form-model-item label="键" prop="key" required>
          <l-input
            v-model="editDialog.data.key"
            placeholder="请输入键"
          />
        </l-form-model-item>
        <l-form-model-item label="值" prop="value" required>
          <l-input
            v-model="editDialog.data.value"
            placeholder="请输入值"
          />
        </l-form-model-item>
      </l-form-model>
    </l-modal>
    <!-- 保存记录-END -->
  </div>
</template>

<script>
export default {
  name: 'AuthAppList',
  data() {
    return {
      tableData: {
        isLoading: false,
        total: 0,
        page: 1,
        size: 10,
        list: []
      },
      editDialog: {
        data: {
          key: '',
          value: ''
        },
        save: false,
        show: false
      }
    }
  },
  mounted() {
    this.search(1)
  },
  methods: {
    async search(page) {
      page = (page && page.current) || page || this.tableData.page
      const param = {
        page: page || 1,
        size: this.tableData.size
      }
      this.tableData.list = []
      this.tableData.isLoading = true

      try {
        const data = await this.$store.dispatch('auth/authConfig', param)
        this.tableData.isLoading = false
        this.tableData.list = data.list || []
        this.tableData.total = data.count
        this.tableData.page = page || 1
      } catch (e) {
        this.tableData.isLoading = false
      }
    },
    resetForm() {
      this.search(1)
    },
    editRow(row) {
      row = row || {}
      this.editDialog.data = JSON.parse(JSON.stringify(row))
      for (const key in this.editDialog.errmsg) {
        this.editDialog.errmsg[key] = ''
      }
      this.editDialog.show = true
    },
    async saveDialog() {
      this.editDialog.save = true
      try {
        await this.$store.dispatch('auth/authConfigSave', this.editDialog.data)
        this.editDialog.save = false
        this.editDialog.show = false
        this.search()
      } catch (e) {
        this.editDialog.save = false
      }
    }
  }
}
</script>

<style lang="scss">
.system-index-btns {
    text-align: right;
    & i {
      font-size: 12px;
      margin-right: 8px;
      vertical-align: top;
    }
    & span {
      display: inline-block;
      vertical-align: top;
    }
  }
.device-info {
  .device-info-body {
    margin: 15px 15px 70px;
  }
  .info-line {
    margin-bottom: 10px;
    span {
      display: inline-block;
      padding: 5px 0;
      color: $l-text-primary;
      font-size: 14px;
      line-height: 24px;
    }
    span.name {
      min-width: 110px;
      line-height: 24px;
    }
  }
}
</style>
